import Layout from '@/layout';
var institutionRouter = {
  path: '/institution',
  component: Layout,
  redirect: '/institution/list',
  name: 'Institution',
  meta: {
    title: '机构管理',
    icon: 'mechanism'
  },
  alwaysShow: true,
  children: [{
    path: 'area',
    component: function component() {
      return import('@/views/institution/area');
    },
    name: 'AreaList',
    meta: {
      title: '地区管理'
    }
  }, {
    path: 'address',
    component: function component() {
      return import('@/views/institution/address');
    },
    name: 'StreetList',
    meta: {
      title: '街道管理'
    }
  }, {
    path: 'list',
    component: function component() {
      return import('@/views/institution/list');
    },
    name: 'MedicalInstitutionList',
    meta: {
      title: '医疗机构'
    }
  }]
};
export default institutionRouter;