import Layout from '@/layout';
var userRouter = {
  path: '/user',
  component: Layout,
  redirect: '/user/list',
  name: 'User',
  meta: {
    title: '人员管理',
    icon: 'system'
  },
  alwaysShow: true,
  children: [{
    path: 'member',
    component: function component() {
      return import('@/views/user/member');
    },
    name: 'PersonnelList',
    meta: {
      title: '人员管理'
    }
  }, {
    path: 'addMember',
    component: function component() {
      return import('@/views/user/addMember');
    },
    name: 'PersonnelAdd',
    meta: {
      title: '人员'
    },
    hidden: true
  }, {
    path: 'list',
    component: function component() {
      return import('@/views/user/user');
    },
    name: 'UserList',
    meta: {
      title: '用户管理'
    }
  }, {
    path: 'addUser',
    component: function component() {
      return import('@/views/user/addUser');
    },
    name: 'UserCreate',
    meta: {
      title: '用户'
    },
    hidden: true
  }, {
    path: 'index',
    component: function component() {
      return import('@/views/user/role');
    },
    name: 'RoleList',
    meta: {
      title: '权限设置'
    }
  }
  // {
  //   path: 'edit/:id(\\d+)',
  //   component: () => import('@/views/mechanism/edit'),
  //   name: 'UserEdit',
  //   hidden: true
  // },
  // {
  //   path: 'create',
  //   component: () => import('@/views/mechanism/create'),
  //   hidden: true,
  //   name: 'UserCreate',
  //   meta: { title: '新建用户' }
  // },
  ]
};
export default userRouter;