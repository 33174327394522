import Layout from '@/layout';
var questionnaireRouter = {
  path: '/',
  component: Layout,
  redirect: '/list',
  name: 'Index',
  meta: {
    title: '首页',
    icon: 'dashbord'
  },
  children: [{
    path: 'list',
    component: function component() {
      return import('@/views/questionnaire/index');
    },
    name: 'IndexDashboard',
    meta: {
      title: '首页',
      icon: 'dashbord'
    }
  }]
};
export default questionnaireRouter;