import Cookies from 'js-cookie';
var TokenKey = 'Admin-Token';
var RoleKey = 'Admin-Role';
var RoleViewKey = 'Admin-RoleView';
var UserDataKey = 'User-Data';
export function getToken() {
  return Cookies.get(TokenKey);
}
export function setToken(token) {
  return Cookies.set(TokenKey, token);
}
export function removeToken() {
  return Cookies.remove(TokenKey);
}
export function getHywRole() {
  return JSON.parse(Cookies.get(RoleKey) || "[]");
}
export function setHywRole(token) {
  return Cookies.set(RoleKey, token);
}
export function removeHywRole() {
  return Cookies.remove(RoleKey);
}
export function getRoleView() {
  return Cookies.get(RoleViewKey);
}
export function setRoleView(data) {
  return Cookies.set(RoleViewKey, data);
}
export function removeRoleView() {
  return Cookies.remove(RoleViewKey);
}
export function setUserData(data) {
  return Cookies.set(UserDataKey, data);
}
export function removeUserData() {
  return Cookies.remove(UserDataKey);
}