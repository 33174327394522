import Layout from '@/layout';
var questionRouter = {
  path: '/question',
  component: Layout,
  redirect: '/question/bank',
  name: 'Question',
  meta: {
    title: '',
    icon: 'question'
  },
  // alwaysShow: true,
  children: [{
    path: 'bank',
    component: function component() {
      return import('@/views/question/bank');
    },
    name: 'QuestionList',
    meta: {
      title: '题库管理'
    }
  }]
};
export default questionRouter;