import Layout from '@/layout';
var agedRouter = {
  path: '/aged',
  component: Layout,
  redirect: '/aged/list',
  name: 'Aged',
  meta: {
    title: '评估对象',
    icon: 'user'
  },
  // alwaysShow: true,
  children: [{
    path: 'list',
    component: function component() {
      return import('@/views/aged/list');
    },
    name: 'AgedList',
    meta: {
      title: '评估对象'
    }
  }, {
    path: 'target',
    component: function component() {
      return import('@/views/aged/target');
    },
    name: 'AgedAdd',
    meta: {
      title: '新增评估对象'
    },
    hidden: true
  }]
};
export default agedRouter;